<template>
  <div class="v-map-field v-text-field" style="margin-left:1px">
    <v-subheader
      light
      class="v-label v-label--active"
      style="margin-bottom: -30px;">
        {{ $t(label) }}
    </v-subheader>
    <Map
      ref="map"
      v-bind="options"
      :value.sync="value"
      :mapLayers="mapLayers"
      :hits="hits"
      :headers="readFields"
      :create="createObj"
      :state.sync="state"
      :editable="!readonly && editable"
      :fullLoading="extLoading"
      v-on:edit="edit"
    />
  </div>
</template>
<script>
import Map from '@/components/Map.vue';
import _ from '@/misc/lodash';
import { buildAllGeoObjects } from '@/misc/buildGeoObject';
import mapFieldMixin from '@/mixins/mapField';

export default {
  name: 'mapField',
  mixins: [mapFieldMixin],
  components: {
    Map,
  },
  props: {
    componentData: {
      type: Object,
    },
    value: {
      type: [Array, Object],
      default: undefined,
    },
    label: {
      type: String,
      default: 'fields.map',
    },
    editable: {
      type: String,
      default: '',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    extLoading: {
      type: Boolean,
      default: false,
    },
    mapLayers: {
      type: Array,
      default: () => ([]),
    },
  },
  data: () => ({
    state: {
      lat: 0,
      lng: 0,
      zoom: 0,
      bounds: null,
    },
    hits: [],
    fetchDataDebounce: () => {},
  }),
  computed: {
    createObj() {
      return _.isEmpty(this.value);
    },
    bounds() {
      if (this.state.bounds !== null) {
        // Use try/catch to prevent leaflet errors
        try {
          return [
            Object.values(this.state.bounds.getNorthEast()).reverse(),
            Object.values(this.state.bounds.getNorthWest()).reverse(),
            Object.values(this.state.bounds.getSouthWest()).reverse(),
            Object.values(this.state.bounds.getSouthEast()).reverse(),
          ];
        } catch (error) {
          // Random error with get bounds (_leaflet_pos)
          console.error(error);
        }
      }
      return [];
    },
    dataQuery() {
      // Not fetch other data in editable mode to prevent eventual errors with geoman plugin
      if (this.editable === '') {
        const query = this.queryParams;
        if (!_.isEmpty(this.bounds)) {
          query.filters = {
            spatial: this.bounds,
          };
        }
        return query;
      }
      return {};
    },
  },
  watch: {
    dataQuery() {
      this.fetchDataDebounce();
    },
  },
  mounted() {
    this.fetchDataDebounce = _.debounce(() => {
      this.fetchData();
    }, 500);

    if (this.options && !_.isEmpty(this.options.state)) {
      this.state = { ...this.state, ...this.options.state };
    }
  },
  methods: {
    async fetchData() {
      let result = [];
      this.$nextTick(async () => {
        if (!_.isEmpty(this.dataQuery)) {
          const { body } = await this.$store.dispatch('crud/SEARCH', {
            object: this.object,
            alias: this.alias,
            body: this.dataQuery,
            all: false,
          });

          if (typeof body !== 'undefined') {
            // Push Computed geoObject
            result = await buildAllGeoObjects(
              // Only current objects
              body.filter((hit) => (hit.current)),
              // layer is header
              this.geoHeader,
            );
          }
        }

        this.$nextTick(() => {
          this.hits = result;
        });
      });
    },
    async edit({ coordinates }) {
      this.$emit('change', coordinates);
    },
  },
};
</script>
<style>
  .v-map-field .leaflet-top {
    z-index: 7;
  }
</style>
